import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  private readonly baseUrl = environment.eventBaseUrl;
  private readonly apiVersion = 'v1';
  private readonly inviteUrl = `${this.baseUrl}/${this.apiVersion}/invite`;
  constructor(private _http: HttpClient) {}

  public inviteGuests(eventId: string, emails: string[]): Observable<string[]> {
    return this._http
      .post<any>(`${this.inviteUrl}/${eventId}`, { toGuestEmails: emails })
      .pipe(map((response) => response.result as string[]));
  }

  public getInvitedGuests(eventId: string): Observable<string[]> {
    return this._http.get<any>(`${this.inviteUrl}/${eventId}`).pipe(map((response) => response.result as string[]));
  }

  public isUserAlreadyInvited(eventId: string, email: string): Observable<boolean> {
    return this._http
      .get<any>(`${this.inviteUrl}/${eventId}/isInvited/${email}`)
      .pipe(map((response) => response.result as boolean));
  }
}
