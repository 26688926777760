import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialModule } from '../material.module';
import { EventFormComponent } from './event-form/event-form.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventRoutingModule } from './event-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EventListComponent } from './event-list/event-list.component';
import { StoreObjectPipe } from '../common/store-pipe';
import { MatIconModule } from '@angular/material/icon';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { EventOverviewComponent } from './event-overview/event-overview.component';
import { EventsStore } from './store/events.store';
import { InviteGuestComponent } from './invite-guest/invite-guest.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { MatBadgeModule } from '@angular/material/badge';
import { GuestEditComponent } from './guest-edit/guest-edit.component';

@NgModule({
  declarations: [
    EventFormComponent,
    EventListComponent,
    StoreObjectPipe,
    EventOverviewComponent,
    InviteGuestComponent,
    InviteUsersComponent,
    GuestEditComponent,
  ],
  imports: [
    CommonModule,
    EventRoutingModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDialogModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MaterialModule,
    MatExpansionModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EventsStore],
      multi: true,
    },
  ],
})
export class EventModule {}
function initializeApp(eventsStore: EventsStore): () => Promise<void> {
  return () =>
    new Promise<void>((resolve, reject) => {
      resolve();
    });
}
