<div>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15">
    <div class="add-button">
      <button aria-hidden="false" aria-label="Create event" routerLink="./create" mat-fab>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="eventsStore.eventsLoading | store" fxlayout="row" fxLayoutAlign="center">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="eventsStore.events | store as events" fxLayout="row wrap">
    <mat-card *ngFor="let event of events" fxFlex="30" fxLayout="column">
      <mat-card-title>{{ event.name }}</mat-card-title>
      <mat-card-subtitle>When: {{ event.dateOccurring | date: 'M/d/yy, h:mm a' }}</mat-card-subtitle>
      <mat-card-actions class="action-buttons">
        <button mat-stroked-button
                class="mat-button mat-primary"
                [matBadge]="invitesStore.getInvitedGuestCount(event.id ?? '')"
                matBadgeColor="accent"
                (click)="openInviteUsersDialog(event.id)">
          <mat-icon>group_add</mat-icon>
        </button>
        <button mat-button [routerLink]="'./' + event.id">
          <mat-icon>info_outline</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
