export const environment = {
  production: false,
  eventBaseUrl: 'https://la-event-service.salmonplant-30a4b65e.westus3.azurecontainerapps.io/api',
  auth: {
    domain: 'lyraapps.us.auth0.com',
    clientId: '4RIS9YltKwIGmvGPSmZva90iQUctLUkx',
    audience: 'https://tverry.com:8080',
    redirectUri: window.location.origin,
    errorPath: '/error',
  },
  httpInterceptor: {
    allowedList: [`http://tverry.com:8080/*`],
  },
  firebase: {
    apiKey: 'AIzaSyBINeMdz2hSzDd7c4ikJ9bVuB8Lf4cz6kg',
    authDomain: 'rsvp-me-io-dev.firebaseapp.com',
    projectId: 'rsvp-me-io-dev',
    storageBucket: 'rsvp-me-io-dev.appspot.com',
    messagingSenderId: '169651212340',
    appId: '1:169651212340:web:79b9c64f5a84ccf8e6fa61',
    measurementId: 'G-5D8ZQ831S1',
  },
};
