import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _snackBar: MatSnackBar) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (error) {
          switch (error.status) {
            case 500:
              this._snackBar.open('Sorry. Looks like something went wrong. Please try again or contact support', 'Ok', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              });
              break;
            case 401:
              this._snackBar.open("Oops you don't have permission to do that", 'Ok', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              });
              break;
            case 404:
            case 400:
              this._snackBar.open(
                "Looks like there's a bug see if this helps: " + error.error.map((a: { toString: () => any }) => a.toString()),
                'Ok',
                {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                }
              );
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
