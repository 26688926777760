import { Injectable } from '@angular/core';
import { StoreObject } from 'src/app/common/store-object';
import { EventService } from '../event.service';
import { Event } from '../model/event';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsStore {
  public events = new StoreObject<Event[] | undefined>(undefined);
  public eventsLoading = new StoreObject<boolean>(false);

  constructor(private _eventService: EventService, private _analytics: AngularFireAnalytics) {}

  public async initializeEvents(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.eventsLoading.set(true);
      this._eventService.getEvents().subscribe(
        (events: Event[]) => {
          this.events.set(events);
          this.eventsLoading.set(false);
          resolve();
        },
        () => {
          this.eventsLoading.set(false);
          reject();
        }
      );
    });
  }

  public async getEvent(id: string): Promise<Event | undefined> {
    let currentEvents = this.events.get();
    if (!currentEvents) {
      await this.initializeEvents();
      currentEvents = this.events.get();
    }

    return currentEvents?.find((event: Event) => event.id === id);
  }

  public async createEvent(event: Event): Promise<void> {
    const id = await firstValueFrom(this._eventService.createEvent(event));
    const currentEvents = this.events.get();

    event.id = id;
    currentEvents?.push(event);
    await this._analytics.logEvent('create_event', { event_id: id });
  }
}
