import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { initializeAnalytics, provideAnalytics } from '@angular/fire/analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import { UserInfoComponent } from './user-info/user-info.component';
import { MaterialModule } from './material.module';
import { EventModule } from './event/event.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';

@NgModule({
  declarations: [AppComponent, LoginComponent, UserInfoComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    EventModule,
    AuthModule.forRoot({
      domain: 'lyraapps.us.auth0.com',
      clientId: '4RIS9YltKwIGmvGPSmZva90iQUctLUkx',
      audience: 'https://tverry.com:8081',
      scope: 'read:events write:events',
      httpInterceptor: {
        allowedList: [env.eventBaseUrl + '*'],
      },
    }),
    MaterialModule,
    AngularFireModule.initializeApp(env.firebase),
    AngularFireAnalyticsModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
