import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from './model/event';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly baseUrl = environment.eventBaseUrl;
  private readonly apiVersion = 'v1';

  constructor(private http: HttpClient) {}

  public createEvent(event: Event): Observable<string> {
    return this.http
      .post<any>(`${this.baseUrl}/${this.apiVersion}/event`, event)
      .pipe(map((response) => response.result as string));
  }

  public getEvents(): Observable<Event[]> {
    return this.http.get<any>(`${this.baseUrl}/${this.apiVersion}/event`).pipe(map((response) => response.result as Event[]));
  }
}
