import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvitedGuestsStore } from '../store/invited-guests.store';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss'],
})
export class InviteUsersComponent implements OnInit {
  public formGroup = this._fb.group({
    emails: this._fb.array([]),
  });
  public allControlsValidated = false;

  public get emailControls(): FormArray {
    return this.formGroup.controls.emails;
  }

  private getNewEmailControl(): AbstractControl {
    return this._fb.control('', [Validators.email, Validators.required]);
  }

  constructor(
    private _fb: FormBuilder,
    public inviteGuestStore: InvitedGuestsStore,
    public dialogRef: MatDialogRef<InviteUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { eventId: string }
  ) {}

  public ngOnInit(): void {
    this.addEmail();
  }

  public getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return 'You must enter a value';
    } else if (control.hasError('email')) {
      return 'Not a valid email';
    }

    return control.hasError('emailAlreadyInvited') ? 'Guest already invited' : '';
  }

  public removeEmail(index: number): void {
    this.emailControls.removeAt(index);
    if (this.formGroup.valid) {
      this.allControlsValidated = true;
    }
  }

  public addEmail(): void {
    this.emailControls.push(this.getNewEmailControl());
    this.allControlsValidated = false;
  }

  public async validateEmail(control: AbstractControl): Promise<void> {
    if (!control.valid) {
      return;
    }
    const userAlreadyInvited = await this.inviteGuestStore.isUserAlreadyInvited(this.data.eventId, control.value);
    if (userAlreadyInvited) {
      control.setErrors({ emailAlreadyInvited: true });
      return;
    }
    this.allControlsValidated = true;
  }

  public async inviteUsers(): Promise<void> {
    const emails = this.emailControls.value;
    await this.inviteGuestStore.inviteGuests(this.data.eventId, emails);
    this.dialogRef.close();
  }
}
