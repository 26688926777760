import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public opened = false;

  constructor(public auth: AuthService, private analytics: AngularFireAnalytics) {}
  title = 'lyra-signup';

  public async ngOnInit(): Promise<void> {
    const user = await firstValueFrom(this.auth.user$);
    if (user && user.sub) {
      await this.analytics.setUserId(user.sub);
      await this.analytics.setUserProperties({ email: user.email });
      await this.analytics.setAnalyticsCollectionEnabled(true);
    }
  }
}
