import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreObject } from '../../common/store-object';
import { EventService } from '../event.service';
import { InviteService } from '../service/invite.service';
import { EventsStore } from './events.store';

class InvitedGuest {
  public id!: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;

  public static fromApi(data: string): InvitedGuest {
    return Object.assign(new InvitedGuest(), data);
  }

  public static fromApiList(data: string[]): InvitedGuest[] {
    return data.map((item: string) => InvitedGuest.fromApi(item));
  }
}

@Injectable({
  providedIn: 'root',
})
export class InvitedGuestsStore {
  public invitedGuests = new StoreObject<Map<string, InvitedGuest[]>>(new Map());

  constructor(private inviteService: InviteService, private eventStore: EventsStore) {}

  public async refreshInvitedGuests(): Promise<void> {
    const currentEvents = this.eventStore.events.get();
    if (currentEvents) {
      for (const event of currentEvents) {
        if (event.id) {
          await this.setInvitedGuestsFromDB(event.id);
        }
      }
    }
  }

  public getInvitedGuests(eventId: string): InvitedGuest[] {
    const invitedGuests = this.invitedGuests.get();
    if (invitedGuests.has(eventId)) {
      return invitedGuests.get(eventId) ?? [];
    }
    return [];
  }

  public async setInvitedGuestsFromDB(eventId: string): Promise<InvitedGuest[]> {
    const dbGuests = await firstValueFrom(
      this.inviteService.getInvitedGuests(eventId).pipe(map((guests: string[]) => InvitedGuest.fromApiList(guests)))
    );
    this.invitedGuests.set(new Map([[eventId, dbGuests]]));
    return dbGuests;
  }

  public getInvitedGuestCount(eventId: string): number {
    return this.getInvitedGuests(eventId).length;
  }

  public async inviteGuests(eventId: string, emails: string[]): Promise<void> {
    const invitedGuestIds = await firstValueFrom(this.inviteService.inviteGuests(eventId, emails));
    const invitedGuests = this.invitedGuests.get();
    if (invitedGuests.has(eventId)) {
      const fullList = [...((invitedGuests.get(eventId) ?? []) as InvitedGuest[]), ...InvitedGuest.fromApiList(invitedGuestIds)];
      invitedGuests.set(eventId, fullList);
    } else {
      invitedGuests.set(eventId, InvitedGuest.fromApiList(invitedGuestIds));
    }
  }

  public async isUserAlreadyInvited(eventId: string, email: string): Promise<boolean> {
    return firstValueFrom(this.inviteService.isUserAlreadyInvited(eventId, email));
  }
}
