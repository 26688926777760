import { Component, Input, OnInit } from '@angular/core';
import { InvitedGuestsStore } from '../store/invited-guests.store';

@Component({
  selector: 'app-guest-edit',
  templateUrl: './guest-edit.component.html',
  styleUrls: ['./guest-edit.component.scss'],
})
export class GuestEditComponent implements OnInit {
  @Input() public eventId: string | undefined;

  constructor(public invitedGuestStore: InvitedGuestsStore) {}

  public async ngOnInit(): Promise<void> {
    console.log(this.eventId);
    await this.invitedGuestStore.setInvitedGuestsFromDB(this.eventId ?? '');
  }
}
