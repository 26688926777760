import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsStore } from '../store/events.store';
import { Event } from '../model/event';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss'],
})
export class EventOverviewComponent implements OnInit, OnDestroy {
  public event!: Event;
  private $componentDestroyed = new Subject<void>();

  constructor(public eventsStore: EventsStore, private _activeRoute: ActivatedRoute, private _router: Router) {}

  ngOnDestroy(): void {
    this.$componentDestroyed.next();
    this.$componentDestroyed.complete();
  }

  ngOnInit(): void {
    this._activeRoute.paramMap.subscribe(async (paramMap: ParamMap) => {
      const eventId = paramMap.get('eventId') ?? '';
      this.event = (await this.eventsStore.getEvent(eventId)) ?? {};
    });
  }
}
