import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsStore } from '../store/events.store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent {
  public eventForm: FormGroup = this._fb.group({
    name: [undefined, Validators.required],
    dateOccurring: [undefined, Validators.required],
  });
  public creating = false;

  constructor(
    private _eventStore: EventsStore,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _fb: FormBuilder
  ) {}

  async createEvent(): Promise<void> {
    this.creating = true;
    await this._eventStore.createEvent(this.eventForm.value);
    this.creating = false;
    this._router.navigate(['../events'], { relativeTo: this._activeRoute });
  }
}
