import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { InviteUsersComponent } from '../invite-users/invite-users.component';
import { EventsStore } from '../store/events.store';
import { InvitedGuestsStore } from '../store/invited-guests.store';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit {
  constructor(public eventsStore: EventsStore, public dialog: MatDialog, public invitesStore: InvitedGuestsStore) {}

  public async ngOnInit(): Promise<void> {
    await this.eventsStore.initializeEvents();
    await this.invitesStore.refreshInvitedGuests();
  }

  public async openInviteUsersDialog(eventId: string | undefined): Promise<void> {
    console.log(eventId);
    const dialogRef = this.dialog.open(InviteUsersComponent, {
      width: '400px',
      disableClose: true,
      data: { eventId },
    });

    await firstValueFrom(dialogRef.afterClosed());
  }
}
