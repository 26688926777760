<div fxFlexFill fxLayout="row" fxLayoutAlign="center">
  <mat-card class="eventCard">
    <mat-card-content>
      <form [formGroup]="eventForm">
        <div fxLayout="column" fxLayoutAlign="space-around">
          <mat-form-field appearance="fill">
            <mat-label>Event Name</mat-label>
            <input formControlName="name" matInput required />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <div class="input"></div>
            <input
              formControlName="dateOccurring"
              placeholder="Choose a date and time"
              matInput
              [ngxMatDatetimePicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [enableMeridian]="true" [showSeconds]="false">
              <!-- Custom icon or text of Apply icon -->
              <ng-template>
                <!-- <mat-icon>star</mat-icon> -->
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <button *ngIf="!creating" color="primary" mat-raised-button [disabled]="!eventForm.valid" (click)="createEvent()">
            Create
          </button>
          <mat-spinner *ngIf="creating" diameter="20"></mat-spinner>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
