<div fxFlexFill fxLayout="column">
  <mat-toolbar color="primary" fxLayout="row">
    <button class="example-icon" aria-label="main menu" mat-icon-button (click)="opened = !opened">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Rsvp Me!</span>
    <span class="spacer"></span>
    <span *ngIf="auth.user$ | async as user">{{ user.name }}</span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="auth.logout()">Log Out</button>
    </mat-menu>
  </mat-toolbar>
  <mat-sidenav-container fxLayout="column">
    <mat-sidenav #sidenav class="sidenav" mode="over" [(opened)]="opened">
      <button class="sidenav-link" routerLink="events" mat-button (click)="sidenav.toggle()">
        <mat-icon> event</mat-icon>
        <span> Events </span>
      </button>
    </mat-sidenav>
    <mat-sidenav-content>
      <div fxFlex fxLayoutAlign="center" *ngIf="auth.isLoading$ | async">
        <mat-spinner ></mat-spinner>
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
