<h2 mat-dialog-title>Add Guests</h2>
<mat-dialog-content>
<form [formGroup]="formGroup">
  <div formArrayName="emails">
    <div *ngFor="let email of emailControls.controls; let i=index; let f=first">
      <mat-form-field appearance="fill" class="email-input">
        <mat-label>Enter your guests email</mat-label>
        <input aria-label="Input for a guests email"
               matInput placeholder="pat@example.com"
               formControlName="{{i}}"
               required
               (blur)="validateEmail(emailControls.controls[i])">
        <mat-error *ngIf="email.invalid && email.dirty">{{getErrorMessage(emailControls.controls[i])}}</mat-error>
      </mat-form-field>
      <button aria-lable="Remove guest from list" mat-icon-button (click)="removeEmail(i)" *ngIf="!f">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <button aria-label="Add another guest email" mat-stroked-button color="primary" (click)="addEmail()">
      <mat-icon>person_add</mat-icon>
    </button>
  </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button
          aria-label="Save guests to event"
          class="mat-primary" [disabled]="formGroup.invalid || !this.allControlsValidated" (click)="inviteUsers()">Submit</button>
</mat-dialog-actions>
